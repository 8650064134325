@import '../../assets/sass/variable.scss';

.imageUploading {
  > div {
    height: 110px;
    label {
      div {
        div {
          width: 100px !important;
          height: 100px !important;
          display: flex !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
          cursor: pointer;
          padding: 0.3%;
          svg {
            margin-bottom: -10px;
          }
        }
        span {
          font-size: 10px !important;
        }
      }
    }
  }
}
.form {
  label {
    width: 120px;
  }
}
.tagMain {
  display: flex;
  width: 100% !important;
  @media (max-width: 420px) {
    display: block;
  }
  > div {
    > div:nth-child(2) {
      > div {
        min-height: 40px !important;
      }
    }
  }

  > div:nth-child(2) {
    right: 0;
    width: 100%;
    margin-bottom: 35px;
    @media (max-width: 420px) {
      top: 10px;
      width: 100% !important;
    }
    > div {
      > div {
        min-height: 40px;
      }
      textarea {
        border-color: $lightGray;
        border-radius: 4px;
        padding: 8px;
        justify-content: center;
        &:focus-visible {
          outline: 1px solid #000 !important;
          box-shadow: 0 0 0 2px rgba(20, 45, 36, 0.2) !important;
        }
      }
    }
    > div:nth-child(2) {
      top: 15px !important;
      border: 1px solid #b8b3b3;
    }
  }
}
.imageUploadContent {
  @media (max-width: 375px) {
    > div {
      width: 100%;
      div {
        > div {
          width: 100% !important;
        }
      }
    }
  }
}
