.plainBox {
  width: 100%;
  padding: 16px;
  min-height: 100vh;
  background-color: #f7f6f0;
  flex-direction: column !important;
}
.companyName {
  font-size: 24px;
  font-family: Times;
  margin-bottom: 36px;
}

.divider {
  margin-top: 12px !important;
  margin-bottom: 24px !important;
  background-color: black;
}

.title {
  width: 100%;
  float: left;
}

.content {
  width: 100%;
}

.form {
  width: 100%;
  background-color: red;
  &-input-link {
    text-align: right;
    margin-top: -24px !important;
  }
  &-link {
    text-align: right;
  }
}
