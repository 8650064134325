@import '../../../../assets/sass/variable.scss';
.planTxt {
  font-size: 18px;
}
.planMainBox {
  display: flex;
  justify-content: space-between;
  border: 1px solid $lightGray;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  .planBox {
    display: flex;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
    p {
      margin-bottom: 0;
      margin-left: 5px;
      font-family: Times New Roman;
      font-size: 20px;
      font-weight: 400;
    }
  }
  .planSecContent {
    p {
      margin-bottom: 0;
    }
    p:nth-child(1) {
      font-size: 16px;
      font-weight: 700;
    }
    p:nth-child(2) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 400;
      > p {
        font-size: 16px;
        font-weight: 400;
        color: $gray;
        margin-left: 2px;
      }
    }
  }
}
.planChangeTitle {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
}
.changeBtn {
  background-color: #808080 !important;
  color: $white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  height: auto !important;
  margin-top: 20px;
}
.descPlan {
  margin-top: 50px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
.subDescPlan {
  font-size: 14px;
  font-weight: 400;
  color: $gray;
}
.tableMainContent {
  margin-bottom: 100px;
  border-color: $secondaryColor;
  @media (max-width: 1024px) {
    overflow-x: scroll;
  }
  > div {
    table {
      @media (max-width: 1024px) {
        width: 700px;
      }

      thead {
        > tr {
          th {
            border: 1px solid #e3e4e4;
            background-color: $white;
          }
          th:nth-child(1) {
            background-color: #f2f2f2;
            border: 1px solid $white;
          }
        }
      }
      tbody {
        td {
          border: 1px solid #e3e4e4;
          border-top: 0;
          width: 130px;
          p {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 400;
          }
        }
        tr {
          td:nth-child(1) {
            background-color: #f2f2f2;
            border: 1px solid $white;
            font-size: 12px;
            font-weight: 400;
            width: 160px;
          }
        }
      }
    }
  }
}
.activeBtn {
  background-color: $primaryColor !important;
}
