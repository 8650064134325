@import '../../../../assets/sass/variable.scss';
.titleTxt {
  font-size: 18px;
  font-weight: 500;
  max-width: 504px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  @media (max-width: 425px) {
    font-size: 14px;
  }
}
.subTxt {
  font-size: 16px;
  font-weight: 400;
  color: $gray;
  margin-bottom: 3px;
  @media (max-width: 425px) {
    font-size: 14px;
  }
}
.unsubscribeBtn {
  height: 44px !important;
  margin-top: 40px;
  border: 1px solid;
  button {
    padding: 14px 16px;
  }
  span {
    font-size: 16px;
    font-weight: 700;
  }
}
.unsubscribeModal {
  width: fit-content !important;
  > div:nth-child(2) {
    div {
      text-align: center;
    }
    > div:nth-child(3) {
      text-align: end;
    }
  }
  .modalTxt {
    font-size: 14px;
    font-weight: 400;
    p {
      margin-bottom: 0;
    }
  }
}
