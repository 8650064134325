@import '../../../src/assets/sass/variable.scss';
.mainCard {
  width: fit-content;
  margin-bottom: 10px !important;
  > div {
    padding: 16px;
  }
  h3 {
    color: #000;
    margin-top: 10px;
    margin-bottom: 2px;
  }

  .imgContent {
    width: 300px;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
  }
  .detailContent {
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
    .menuBtn {
      transform: rotate(270deg);
    }
  }
}
.menuItem {
  cursor: pointer;
  margin-bottom: 5px;
}
