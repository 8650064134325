@import '../../../src/assets/sass/variable.scss';

.titleContent {
  display: flex !important;
  justify-content: space-between !important;
  padding: 16px 24px;
  @media (max-width: 320px) {
    padding: 12px;
  }
  border-bottom: 1px solid $lightGray;
  &:last-child {
    border: 0 !important;
  }
  > div {
    display: flex !important;
  }
  p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: $gray;
  }
  @media (max-width: 425px) {
    background-color: transparent;
    button {
      font-size: 16px;
      background-color: transparent;
      color: $primaryColor;
      text-transform: uppercase;
    }
  }
  @media (max-width: 320px) {
    button {
      font-size: 12px !important;
      padding: 12px;
    }
  }
}

.titleSubContent {
  margin-left: 10px;
  > div {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center;
    margin-bottom: -3px;
    p {
      margin: 0 5px 0 0 !important;
      font-size: 16px !important;
      font-weight: 700 !important;
      color: $black !important;
    }
  }
  p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: $gray;
  }
}
