@import '../../../../assets/sass/variable.scss';

.cardStyle {
  padding-right: 30px;
  @media (max-width: 1440px) {
    > div {
      > div {
        padding: 20px;
      }
    }
  }
  .cardStyleTitle {
    font-size: 14;
    color: $adminText;
  }
  .cardStyleDetail {
    display: flex;
    justify-content: space-between;
    .cardStyleSupporter {
      font-size: 32px;
      font-weight: bold;
      margin-top: 15px;
    }
    .cardStyleSupportNumber {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;

      .cardStyleUpArrow {
        margin-right: 5px;
        margin-top: 7px;
      }
      .cardStylePoints {
        font-size: 14px;
        margin: 2px 2px 0 4px;
      }
    }
  }
}

.cardSupportAmountStyle {
  padding: 0;
  @media (max-width: 1440px) {
    > div {
      > div {
        padding: 20px;
      }
    }
  }
  .cardSupportAmount {
    display: flex;
    margin: 3px 0;
    .cardSupportAmountTitle {
      font-size: 14px;
      color: $adminText;
    }
    .cardSupportAmountDetail {
      display: flex;
      justify-content: space-between;
      .cardSupportAmountSupporter {
        font-size: 24px;
        font-weight: bold;
        margin-top: 15px;
        @media (max-width: 1440px) {
          font-size: 20px;
        }
      }
      .cardSupportAmountSupportNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        .cardSupportAmountUpArrow {
          margin-right: 5px;
          margin-top: 7px;
        }
        .cardSupportAmountPoints {
          font-size: 14px;
          margin: 2px 2px 0 4px;
        }
      }
    }
    .cardSupportAmountIcon {
      @media (max-width: 1440px) {
        min-width: 80px !important;
      }
    }
    .cardSupportAmountText {
      @media (max-width: 1440px) {
        padding-left: 10px;
      }
    }
  }
}

.tableColumn {
  display: flex;
  .tableColumnTitle {
    margin-top: 15px;
    margin-left: 15px;
    p {
      margin-bottom: 0em !important;
    }
  }
  .usrImg {
    border-radius: 30px;
    overflow: hidden;
  }
  p {
    margin-bottom: 0em !important;
  }
}
.chart {
  margin: 20px 0;
  position: relative;
  .recharts-legend-wrapper {
    position: absolute;
    content: '';
    top: 30px;
    right: 30px;
  }
}
.tableSupportStatus {
  padding: 15px;
  background-color: $white !important;
  th {
    background-color: $white !important;
  }
}
