.missionContent {
  img {
    height: 200px;
    border-radius: 8px;
  }
  padding: 0 16px;
  p {
    margin-bottom: 0;
  }
}
.storyContent {
  width: fit-content;
}
