@import '../../assets/sass/variable.scss';

.totalAmountBox {
  > div {
    padding: 20px !important;
  }
  .totalAmountTitle {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.totalAmountHistoryCard {
  > div {
    padding: 30px !important;
  }
}
.energyText {
  font-family: 'Times New Roman';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.totalAmountTitleMobile {
  margin-top: 26px;
  margin-bottom: 29px;
  font-size: 18px;
  font-weight: 500;
}
.totalEnergyMain {
  display: flex !important;
  justify-content: center;
  > div {
    border-bottom: 2px solid $lightBrown;
    width: 231px;
    display: flex;
    padding-bottom: 8px;
    justify-content: center;
    > div {
      display: flex;
      align-items: center;
    }
  }
}
