@import '../../../../assets/sass/variable.scss';

.userContentMainSec {
  position: unset !important;
}
.row {
  margin-top: 3%;
  padding: 0 20px;
  position: relative;
}
.userContent {
  display: flex !important;
  margin: 8px 0px;
  align-items: center;
  p {
    margin: 0;
  }
}
.userContentTexts {
  display: flex !important;
  margin: 8px 0px;
  align-items: center;
  p {
    margin: 0;
  }
  @media (max-width: 425px) {
    display: block !important;
  }
}
.userContentBtn {
  text-align: end;
  display: flex !important;
  justify-content: flex-end;
  div {
    button {
      background-color: transparent !important;
    }
  }
  @media (max-width: 425px) {
    position: absolute !important;
    top: 10px;
    right: 20px;
    display: block !important;
    div {
      button {
        background-color: transparent !important;
        font-size: small !important ;
        margin-bottom: 4px;
        width: 145px !important;
      }
    }
  }
}
.userNameText {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
.userNameLevalText {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  @media (max-width: 425px) {
    margin: 0 10px !important;
  }
}
.diamondImage {
  width: 16px !important;
  height: 16px !important;
  margin: 0 8px;
}
.followerText {
  color: #7d7d7d;
  margin: 15px 0;
  font-weight: 400;
  font-size: 14px;
}
.followerCount {
  color: '#000';
  margin-right: 5px !important;
}

.userContentMain {
  display: flex;
  align-items: center;
  @media (max-width: 543px) {
    padding: 0 !important;
  }
  @media (max-width: 425px) {
    display: block !important;
    p {
      margin: 10px 0;
    }
  }
}

.userNamePart {
  display: flex;
  align-items: center;
}

.followerMain {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.userContentMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.locationImage {
  width: 12px !important;
  height: 14px !important;
  margin-right: 10px;
}
.medalImage {
  width: 16px !important;
  height: 18px !important;
  margin-right: 5px;
}
.locationText {
  color: #7d7d7d;
  font-weight: 900;
  font-size: 14px;
}
.linkText {
  color: #1662dd !important;
  font-weight: 900;
  font-size: 14px;
}
.listMainContent {
  li {
    padding: 6px 0px !important;
    > div {
      align-items: center !important;
    }
    @media (max-width: 320px) {
      display: block !important;
    }
  }
  h4 {
    margin-bottom: 0 !important;
  }
}
.listDescription {
  font-size: 12px;
  @media (max-width: 425px) {
    font-size: 11px;
  }
}
.listTitle {
  display: flex;
  align-items: center;
  border-width: 2px solid;
  @media (max-width: 425px) {
    font-size: 11px;
  }
}
.userProgress {
  width: 180px !important;
  margin: 0 15px !important;
  > div {
    position: relative;
    > div {
      background: #e7e3e3 !important;
      > div {
        height: 16px !important;
      }
    }
  }
}
.progressMain {
  position: relative;
  > div {
    @media (max-width: 543px) {
      margin: 0 !important;
    }
  }
}
.progressPer,
.progressText {
  position: absolute;
  top: 0;
}
.progressPer {
  left: 18%;
  color: $white;
}
.progressText {
  position: absolute;
  top: 0;
  right: 50%;
}
.tabMain {
  width: 100% !important;
  justify-content: center;
  > div:first-child {
    width: 100% !important;
    > div {
      width: 100%;
      > div {
        width: 99%;
        div:nth-child(1),
        div:nth-child(2) {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
.blankContent {
  height: 300px !important;
}

// user-post-details

.image {
  width: 100%;
  height: 350px;
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}
.subContent {
  @media (max-width: 425px) {
    background-color: transparent !important;
  }
  border-radius: 0;
  border: 0;
  > div {
    padding: 24px;
    @media (max-width: 425px) {
      padding: 0 10px;
    }
  }
  .tagLikeContent {
    display: flex;
    align-items: center;
  }
  .likeContent {
    display: flex;
    align-items: center;
    p {
      margin: 0 0 0 5px;
    }
  }
  .subMainContent {
    @media (max-width: 425px) {
      display: none;
    }
  }
  .userDetailsContent {
    margin: 20px 0;
    display: flex;
    @media (max-width: 425px) {
      margin-bottom: 0px;
    }
  }
  .commentedUserDetailsContent {
    @media (max-width: 425px) {
      display: flex;
      align-items: flex-end;
      padding-bottom: 25px;
    }
  }
  .mobileUserContent {
    display: none;
    @media (max-width: 425px) {
      display: block;
    }
    .likeContent {
      margin-bottom: 20px;
    }
  }
  .mobileTitle {
    margin-bottom: 0;
  }
  .titleContent {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > div {
      div {
        h4 {
          font-size: 16px;
          font-weight: 700;
          margin: 0 5px 0 0;
        }
        display: flex;
        align-items: center;
      }
      p {
        font-size: 12px;
        font-weight: 900;
        color: #999;
      }
    }

    .titleSubLikeContent {
      margin-top: -25px;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  .descriptionContent {
    p {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      text-transform: uppercase;
    }
    @media (max-width: 425px) {
      padding: 0 5px;
    }
  }
}

.midSubtitleContent {
  margin-left: 16px;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      div {
        h4 {
          font-size: 16px;
          font-weight: 700;
          margin: 0 5px 0 0;
        }
        display: flex;
        align-items: center;
      }
      p {
        font-size: 12px;
        font-weight: 900;
        color: #999;
      }
    }
    .followBtn {
      margin-top: -10px;
      @media (max-width: 425px) {
        margin-top: -10px;
        button {
          background-color: transparent !important;
        }
      }
    }
  }
}
.midSubDescriptionContent {
  font-size: 14px;
  font-weight: 400;
}
.commentContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 425px) {
    margin-right: 12px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  p {
    margin: 0 0 0 5px;
    font-size: 14px;
    font-weight: 400;
    color: #7d7d7d;
  }
}

.commentDescription {
  margin-left: 16px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  @media (max-width: 425px) {
    background-color: #fff !important;
  }
  p {
    font-size: 12px;
    font-weight: 400;
  }
}
.commentDate {
  margin-left: 16px;
  p {
    font-size: 10px;
    font-weight: 900;
    color: #7d7d7d;
  }
}
.dividerContent {
  margin: 12px 0 !important;
}
.commentMainContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 425px) {
    display: block;
    .commentSubmitBtn {
      text-align: end;
      margin-right: 24px;
      // border: 1px solid;
    }
  }
}
.commentBtn {
  background-color: #808080 !important;

  height: 44px !important;
  width: 114px !important;

  &:hover {
    background-color: #808080 !important;
    border-color: #808080 !important;
  }

  p {
    margin: 0;
    color: #f8f8f8;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.inputContnet {
  display: flex;
  align-items: center;
  width: 100% !important;

  > div {
    margin-bottom: 0 !important;
    width: 100%;
    padding: 0 24px;
  }
}
.commentUserContent {
  display: flex;
  align-items: center;
  width: 100%;
}
.tagMain {
  display: flex !important;
  width: fit-content !important;
  align-items: center;
  span {
    background-color: transparent;
  }
}

//Follower modal
.modalMainContent {
  > div:nth-child(2) {
    @media (max-width: 425px) {
      background-color: #f7f6f0 !important;
    }
    > div:nth-child(2) {
      @media (max-width: 425px) {
        background-color: #f7f6f0 !important;
      }
    }
    > div:nth-child(3) {
      padding: 0;
      max-height: 500px;
      overflow-y: scroll;
    }
  }
}
.redLike {
  color: rgb(245, 69, 78) !important;
}
