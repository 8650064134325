@import '../../assets/sass/variable.scss';

.supportAmountBox {
  @media (max-width: 320px) {
    > div {
      padding: 24px !important;
    }
  }
  @media (max-width: 768px) {
    margin-top: 24px !important;
  }

  .header {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    font-style: normal;
  }

  .options {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    > div {
      margin-top: 15px;
    }
  }

  .supportBtn {
    height: 56px;
    border: #a6966d;
    margin-top: 20px;
    border-radius: 44px;
    background-color: #a6966d !important;
    span {
      color: $white;
      text-transform: uppercase;
    }
    &:hover {
      background-color: #a6966d !important;
    }
  }
}
