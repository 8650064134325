.imageBox {
  .outterBoxRound {
    width: 168px;
    height: 168px;
    padding: 0.3%;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 2px dotted #6c7d8c;

    .innerBoxRound {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      background: #f7f7f7;
      flex-direction: column;
      justify-content: center;

      .image {
        height: 100%;
        width: inherit;
        border-radius: 50%;
      }
    }
  }

  .imageBoxLargeSize {
    width: 730px;
    height: 365px;
  }

  .imageBoxRegularSize {
    width: 360px;
    height: 180px;
  }

  .outterBoxRect {
    height: 100%;
    width: 100%;
    padding: 0.3%;
    border-radius: 2%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 2px dotted #6c7d8c;

    .innerBoxRect {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 2%;
      align-items: center;
      text-align: center;
      background: #f7f7f7;
      flex-direction: column;
      justify-content: center;

      .image {
        height: 100%;
        width: inherit;
      }
    }
  }

  .text {
    font-family: Roboto;
    line-height: 16px;
    text-align: center;
    color: #6c7d8c;
  }
}

.template {
  width: '90vw' !important;
  height: '90vh' !important;
  display: 'flex' !important;
  align-items: 'center' !important;
  justify-content: 'center' !important;
}
