@import '../../assets/sass/variable.scss';

.facilitiesDetail {
  width: 100%;

  img {
    border-radius: 8px;
    height: 248px;
    @media (max-width: 425px) {
      height: 200px !important;
    }
  }

  .reportTitle {
    width: 100%;
    font-size: 18px;
    color: #7d7d7d;
  }

  .cityName {
    font-size: 14px;
    padding: 2px 12px;
    background-color: $secondaryColor;
  }

  .facilityArea {
    margin-top: 16px;
  }

  .facilityInfo {
    margin-top: 48px;
    margin-bottom: 32px;
  }

  .facilityName {
    margin-bottom: 0;
  }

  .facilityText {
    margin-bottom: 0px;
  }

  .progress {
    width: 100%;
  }
  .bookmarkBtn {
    margin: 10px 0px;
  }
  .progressContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
