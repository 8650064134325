@import './../../../../../../assets/sass/variable.scss';

.memberTitle {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .userImage {
    margin-right: 15px;
  }
  .memberTextDetail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .memberUserDetailMain {
      .memberUserDetailMainInnerOne {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .userName {
          margin: 0;
        }
      }
      .memberUserDetailMainInnerSec {
        display: flex;
        align-content: center;
      }
      .rankTag {
        border-radius: 25px;
        height: 20px;
        width: 160px;
        position: relative;
        margin: 0 15px;
        .rankTagInner {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 12px;
          border-radius: 25px;
          background-color: $primaryColor;
        }
      }
      .levelLabelMain {
        display: flex;
        align-items: center;
        .levelLabelInner {
          margin: 0;
          font-size: 15px;
        }
      }

      .userIdName {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.5;
        margin: 0 15px;
      }
    }

    .levelLabel {
      font-size: 14px;
      color: #6c7d8c;
      font-weight: 400;
      text-align: right;
    }
    .totalAmountSupport {
      display: flex;
      h4 {
        font-size: bold;
        font-size: 32px;
      }
      .label {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-top: 5px;
        font-weight: 500;
        margin-left: 5px;
        opacity: 0.5;
      }
    }
  }
}
