@import '../../assets/sass/variable.scss';
.modalContent {
  width: 600px !important;
  .form {
    label {
      width: 120px;
    }
  }
  .imageUploadContent {
    width: 100%;
    > div {
      width: 100%;
      > label {
        > div:first-child {
          width: 100%;
          > div {
            width: 100% !important;
            height: 300px !important;
          }
        }
      }
    }
  }
  .titleContent {
    display: flex;
    margin: 5px 0 30px 0 !important;
    justify-content: center;
  }
  .progressContent {
    display: flex;
    justify-content: space-between;
    > div {
      width: 70%;
      .progressBar {
        > div {
          position: relative;
          > div {
            background: $secondaryColor !important;
            > div {
              height: 20px !important;
              background-color: $primaryColor !important;
            }
          }
        }
      }
      .progressPer {
        position: absolute;
        color: $white;
        left: 10%;
        margin-top: 2px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}
