@import '../../assets/sass/variable.scss';

.adminHeaderRow {
  padding: 0 10px;
}
.adminLogo {
  color: $white;
  font-family: 'Times New Roman';
  font-size: 24px;
}
.menuTitle {
  padding-left: 10px;
  color: $white;
  text-transform: uppercase;
  @media (max-width: 1527px) {
    font-size: 12px;
  }
}
.menuItem {
  display: flex;
  align-items: center;
  position: relative;
}
.activeTab {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: $white;
}

.settingDropdown {
  font-size: 14px;
  color: #202a34;
}

.email {
  font-size: 14px;
  color: #6c7d8c;
}

.accountSettings {
  display: flex;
  color: #6c7d8c;
  min-height: 42px;
  align-items: center;
}

.title {
  padding-left: 8px;
  a {
    color: #6c7d8c;
  }
}
.profileMain {
  text-align: end;
}

.headerProfile {
  cursor: pointer;
}
