@import '../../../src/assets/sass/variable.scss';
.message {
  width: 100%;
}

.detailContent {
  background-color: $white;
  border: 1px solid $lightGray;
  border-radius: 10px;
  .head {
    margin-top: 20px;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: '#6C7D8C';
    font-size: 20px;
    font-weight: bold;
    svg {
      cursor: pointer;
    }
  }
  .headSecContent {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }
    p {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.dividerContent {
  margin: 15px 0;
}
.bodyFirst {
  margin-top: 20px;
  padding: 10px 25px;
}
.bodySecond {
  margin: 20px 0;
  padding: 10px 25px;
  .titleContent {
    font-size: 24px;
    font-weight: 700;
  }
  .descContent {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
  }
}
.deleteContent {
  p {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
  p:nth-child(2) {
    margin: 0;
  }
}
