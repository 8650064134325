//modal

.listMainContent {
  li {
    padding: 6px 0px !important;
    > div {
      align-items: center !important;
    }
    @media (max-width: 320px) {
      display: block !important;
    }
  }
  h4 {
    margin-bottom: 0 !important;
  }
}
.listDescription {
  font-size: 12px;
  @media (max-width: 425px) {
    font-size: 11px;
  }
}
.listTitle {
  display: flex;
  align-items: center;
  border-width: 2px solid;
  @media (max-width: 425px) {
    font-size: 11px;
  }
  span {
    padding-right: 5px;
  }
}
.likeModal {
  > div:nth-child(2) {
    > div:nth-child(3) {
      padding: 10px 15px;
      max-height: 700px;
      overflow-y: scroll;
      button {
        background-color: #7d7d7d;
        color: #fff;
      }
    }
  }
}
