.imageUploading {
  > div {
    height: 110px;
    label {
      div {
        div {
          width: 100px !important;
          height: 100px !important;
          display: flex !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
          cursor: pointer;
          padding: 0.3%;
          svg {
            margin-bottom: -10px;
          }
        }
        span {
          font-size: 10px !important;
        }
      }
    }
  }
}
