@import '../../assets/sass/variable.scss';

.energySymbol {
  width: 40px;
  height: 40px;
  @media (max-width: 544px) {
    width: 24px;
    height: 24px;
  }
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background: $secondaryColor;
  border: 1px solid $secondaryColor;
}
