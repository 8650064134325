@import '../../../src/assets/sass/variable.scss';
.addPostModal {
  width: 800px !important;
}
.postMainContent {
  .inputSelection {
    position: relative;
    > div {
      > div {
        width: 100%;
      }
    }
    img {
      border-radius: 2%;
      width: 100%;
      object-fit: cover;
    }
    .inputStyle {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
    }
  }

  .btnContent {
    width: 20%;
    margin-top: 20px;
  }
}
