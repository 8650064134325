.mainSearch {
  .tabMainContent {
    display: flex;
    position: relative;
    > div {
      width: 100%;
    }
  }
  .addPostBtn {
    position: absolute;
    right: 0;
    top: 0;

    padding: 5px 20px;
    color: #fff;
    background-color: #142d24;
    border-radius: 20px;
    border: 0px;
    @media (max-width: 425px) {
      top: -30px;
    }
  }
}
.tagListContent {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-bottom: 10px;
    span {
      padding: 4px 8px !important;
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.mainCard {
  position: relative;
  @media (max-width: 425px) {
    background-color: transparent !important;
  }

  > div {
    display: flex;
    justify-content: space-between;
    padding: 24px !important;
    @media (max-width: 425px) {
      padding: 0 !important;
    }
  }
  .subFirstContent {
    padding-top: 24px;
  }
  .subThirdContent {
    padding-top: 46px;
    @media (max-width: 425px) {
      position: absolute;
      right: 30px;
      padding: 0;
    }
  }
  div:nth-child(2) {
    margin-left: 10px;
    padding: 0px 15px;
    @media (max-width: 425px) {
      padding: 0px;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #7d7d7d;
    }
    p:nth-child(3) {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .titleContent {
    display: flex;
    align-items: center;
    margin-bottom: -3px;
    p {
      margin: 0 5px 0 0 !important;
      font-size: 16px !important;
      font-weight: 700 !important;
      color: #000 !important;
    }
  }
}

.blankContent {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
