@import '../../../src/assets/sass/variable.scss';

.mainCard {
  margin-bottom: 20px !important;
  width: 100%;

  @media (max-width: 425px) {
    background-color: transparent !important;
    border: 0 !important;
  }
  > div {
    padding: 24px 24px 20px 24px !important;
    @media (max-width: 425px) {
      padding: 0 !important;
      background-color: transparent;
    }
  }

  .titleContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .titleFirstContent {
      display: flex !important;
      p {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
      }
    }
  }

  .titleSubContent {
    margin-left: 10px;
    > div {
      display: flex !important;
      justify-content: flex-start !important;
      align-items: center;
      margin-bottom: -3px;
      p {
        margin: 0 5px 0 0 !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        color: $black !important;
      }
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: $gray;
    }
  }
  .titleSecContent {
    display: flex !important;
    align-items: center;
    svg {
      cursor: pointer;
    }

    > div:first-child {
      svg {
        margin: 0 20px 0 0;
      }
    }
  }
}
.tag {
  position: absolute;
  top: 25px;
  left: 25px;
}
.tagContent {
  display: flex !important;
  margin-top: 16px;
  justify-content: space-between;
  position: relative;
  .detailContent {
    .tagMain {
      display: flex !important;
      width: fit-content !important;
      flex-wrap: wrap;
      margin-bottom: 16px;
      flex-wrap: wrap;
      span {
        background-color: transparent;
      }
      @media (max-width: 425px) {
        padding-top: 170px;
      }
    }
    .descriptionText {
      font-size: 14px;
      font-weight: 400;
      color: #7d7d7d;
    }
  }

  .image {
    @media (max-width: 425px) {
      position: absolute;
      width: 100% !important;
    }
    img {
      width: 160px !important;
      height: 160px;
      object-fit: cover;
      @media (max-width: 425px) {
        width: 100% !important;
      }
    }
    width: fit-content;
  }
}
.footerContent {
  display: flex !important;
  justify-content: space-between;
  > div {
    display: flex !important;
    > div {
      width: fit-content;
      display: flex !important;
      justify-content: flex-start !important;
      align-items: center;
      svg {
        height: 14px;
        width: 15px;
      }
      p {
        margin: 0;
        margin-left: 3px;
      }
    }
    div:nth-child(2) {
      margin-left: 10px;
    }
  }
  .likeContent {
    display: flex !important;
  }
}
.dividerContent {
  margin: 12px 0 !important;
}
.titleSubLikeContent {
  svg {
    width: 20px !important;
    height: 20px !important;
  }
  cursor: pointer;
}
.heartCommentIcon {
  cursor: pointer;
}
