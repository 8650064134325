@import '../../assets/sass/variable.scss';

.mobileHeader {
  height: 72px;
  border-bottom: 1px solid $lightBrown;
  padding: 0 30px;
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
  .logo {
    font-size: 32px;
    font-family: 'Times New Roman';
    text-align: center;
  }

  .searchIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav {
    display: flex;
    padding: 0;
    .list {
      list-style-type: none;
    }

    .headerMenu {
      margin-top: 5px;
    }
  }

  .search {
    display: flex;
    list-style-type: none;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    > div {
      margin: 0;
    }
  }
  .profile {
    display: flex;
    list-style-type: none;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    padding: 8px;
  }
  .notification {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    .mailIcon {
      font-size: '30px';
    }
    @media (max-width: 1024px) {
      justify-content: center;
      padding-left: 15px;
    }
  }
}
.profileSection {
  text-align: right;
  cursor: pointer;
}
.profileDropDown {
  background: $white;
  padding: 4px 0;
  text-align: left;
  border-radius: 4px;
  div {
    cursor: pointer;
    padding: 5px 12px;
  }
}
.donorHeader {
  height: 72px;
  padding: 0 30px;
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
  .logo {
    font-size: 32px;
    font-family: 'Times New Roman';
  }

  .headerMenuDesktop {
    display: flex;
    div {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .nav {
    display: flex;

    .list {
      list-style-type: none;
    }

    .headerMenu {
      width: auto !important;
      margin-top: 5px;
      background-color: transparent;
      border: none;
      li {
        background-color: transparent !important;
        &::after {
          display: none !important;
        }
      }
    }
  }

  .search {
    display: flex;
    list-style-type: none;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    > div {
      margin: 0;
    }
  }
  .profile {
    display: flex;
    list-style-type: none;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    padding: 8px;
  }
  .notification {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    @media (max-width: 1024px) {
      justify-content: center;
      padding-left: 15px;
    }
  }
}
.profileSection {
  text-align: right;
  cursor: pointer;
}

.energyChargeAmount {
  display: flex;
  padding-right: 15px;
  justify-content: flex-end;
  .points {
    min-width: 110px;
    min-height: 40px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    background-color: #e5e2cf;
    justify-content: center;
    @media (max-width: 1024px) {
      width: 100px;
      height: 35px;
    }
    .icon {
      width: 8px;
      height: 16px;
    }
    .count {
      font-size: 20px;
      line-height: 23px;
      font-family: Roboto;
      color: $primaryColor;
      margin-left: 5px;
      @media (max-width: 1024px) {
        font-size: 18px;
      }
    }
  }
}
.energyChargeAmountMobile {
  .points {
    min-width: 110px;
    min-height: 40px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    background-color: #e5e2cf;
    justify-content: center;
    @media (max-width: 1024px) {
      width: 100px;
      height: 35px;
    }
    .icon {
      width: 8px;
      height: 16px;
    }
    .count {
      font-size: 20px;
      line-height: 23px;
      font-family: Roboto;
      color: $primaryColor;
      margin-left: 5px;
      @media (max-width: 1024px) {
        font-size: 18px;
      }
    }
  }
}
.headerText {
  cursor: pointer;
}
