@import '../../assets/sass/variable.scss';

.card {
  width: 800px;
  height: 88px;
  > div:nth-child(2) {
    padding: 16px 24px !important;
    background-color: #fff;
  }
  > div:nth-child(1) {
    padding: 16px 24px !important;
    // background-color: #fff;
    border-bottom: 0.5px solid gray;
  }
  @media (max-width: 543px) {
    height: auto;
  }
}
.userContentMainSec {
  position: unset !important;
}
.row {
  margin-top: 3%;
  padding: 0 20px;
  position: relative;
}
.imageStyle {
  border-radius: 30px;
  width: 60px !important;
  height: 60px !important;
}
.userContent {
  display: flex !important;
  margin: 8px 0px;
  align-items: center;
  p {
    margin: 0;
  }
}
.userContentTexts {
  display: flex !important;
  margin: 8px 0px;
  align-items: center;
  p {
    margin: 0;
  }
  @media (max-width: 425px) {
    display: block !important;
  }
}
.userContentBtn {
  text-align: end;
  display: flex !important;
  justify-content: space-between;
  div {
    button {
      background-color: transparent !important;
    }
  }
  @media (max-width: 425px) {
    position: absolute !important;
    top: 0px;
    right: 20px;
    display: block !important;
    div {
      button {
        font-size: small !important ;
        margin-bottom: 4px;
        width: 145px !important;
      }
    }
  }
}
.userNameText {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
.userNameLevelText {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  @media (max-width: 425px) {
    margin: 0 10px !important;
  }
}
.diamondImage {
  width: 16px !important;
  height: 16px !important;
  margin: 0 8px;
}
.followerText {
  color: #7d7d7d;
  margin: 15px 0;
  font-weight: 400;
  font-size: 14px;
}
.followerCount {
  color: '#000';
  margin-right: 5px !important;
}

.userContentMain {
  display: flex;
  align-items: center;
  @media (max-width: 543px) {
    padding: 0 !important;
  }
  @media (max-width: 425px) {
    display: block !important;
    p {
      margin: 10px 0;
    }
  }
}

.userNamePart {
  display: flex;
  align-items: center;
}

.followerMain {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.userContentMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.locationImage {
  width: 12px !important;
  height: 14px !important;
  margin-right: 10px;
}
.medalImage {
  width: 16px !important;
  height: 18px !important;
  margin-right: 5px;
}
.locationText {
  color: #7d7d7d;
  font-weight: 900;
  font-size: 14px;
}
.linkText {
  color: #1662dd !important;
  font-weight: 900;
  font-size: 14px;
}
.listMainContent {
  li {
    padding: 6px 0px !important;
    > div {
      align-items: center !important;
    }
    @media (max-width: 320px) {
      display: block !important;
    }
  }
  h4 {
    margin-bottom: 0 !important;
  }
}
.listDescription {
  font-size: 12px;
  @media (max-width: 425px) {
    font-size: 11px;
  }
}
.listTitle {
  display: flex;
  align-items: center;
  border-width: 2px solid;
  @media (max-width: 425px) {
    font-size: 11px;
  }
}
.userProgress {
  width: 180px !important;
  margin: 0 15px !important;
  > div {
    position: relative;
    > div {
      background: #e7e3e3 !important;
      > div {
        height: 16px !important;
      }
    }
  }
}
.progressMain {
  position: relative;
  > div {
    @media (max-width: 543px) {
      margin: 0 !important;
    }
  }
}
.progressPer,
.progressText {
  position: absolute;
  top: 0;
}
.progressPer {
  left: 18%;
  color: $white;
}
.progressText {
  position: absolute;
  top: 0;
  right: 50%;
}
.invitationBtn {
  @media (max-width: 320px) {
    text-align: end;
  }
  button {
    @media (max-width: 425px) {
      font-size: 11px !important;
      padding: 6px 11px !important;
    }
    @media (max-width: 320px) {
      font-size: 11px !important;
      padding: 6px 12px !important;
      margin: 10px 75px 0 0;
    }
  }
}
