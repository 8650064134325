@import '../../../src/assets/sass/variable.scss';

.commentMainContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 425px) {
    align-items: start;
  }
}

.commentSubmitBtn {
  text-align: end;
  @media (max-width: 425px) {
    margin-top: 15px;
    padding-right: 15px !important;
  }
  button {
    background-color: #808080 !important;
    margin: 0 !important;
    height: 44px !important;
    width: 114px !important;
    color: $white;
    &:hover {
      background-color: #808080 !important;
      border-color: #808080 !important;
    }

    p {
      margin: 0;
      color: #f8f8f8;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.inputContent {
  display: flex;
  align-items: center;
  width: 100%;
  form {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;

    > div {
      margin-bottom: 0;
    }
    @media (max-width: 425px) {
      padding: 0 12px;
      > div {
        margin-bottom: 0;
      }
    }
  }
  > div {
    margin-bottom: 0;
    width: 100%;
    padding: 0 24px;
    @media (max-width: 425px) {
      padding: 0 15px;
    }
  }
}
.commentUserContent {
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 425px) {
    display: block;
  }
}
.inputSection {
  width: 100%;
}
.commentInputBox {
  background-color: $white;
  padding: 15px;
  border-radius: 8px;
}
