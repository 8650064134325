$primaryColor: #142d24;
$secondaryColor: #e5e2cf;
$darkText: #312b2b;
$white: #ffffff;
$gray: #7d7d7d;
$offWhite: #f7f6f0;
$adminText: #202a34;
$lightGray: #e0e0e0;
$lightText: #6c7d8c;
$lightBrown: #cec5b9;
$lightGreen: #abe5a8;
$cyan: #8ae5da;
$darkPink: #e54588;
$black: #000000;
$golden: #9f8645;
