@import '../../assets/sass/variable.scss';

.supportTitle {
  color: $darkText;
  margin-bottom: 40px;
  margin-top: 10px;
}
.mobileCard {
  margin-top: 30px;
  > h4 {
    padding-left: 22px;
    font-size: 18px !important;
  }
}
