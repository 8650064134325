@import '../../../../assets/sass/variable.scss';
.container {
  margin: 25px auto;
}

.tableColumn {
  display: flex;
  cursor: pointer;
  .title {
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0em !important;
    }
    > img {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin-right: 15px;
    }
    p {
      margin-bottom: 0em !important;
    }
  }
  p {
    margin-bottom: 0em !important;
  }
}

.popoverButton {
  margin: 0;
  cursor: pointer;
  padding: 5px;
}

.facilityTable {
  overflow: hidden;
  background-color: $white !important;
  padding: 15px;
  border: 1px solid $lightGray;
  border-radius: 10px;
  margin-bottom: 50px;
  th {
    background-color: $white !important;
  }
  .searchBox {
    overflow: hidden;
    background-color: #ffffff !important;
    padding-top: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 20px;
    th {
      background-color: $white !important;
    }
    ul {
      padding: 0 20px;
      margin-left: 15px;
      overflow: hidden;
    }
  }
  .actionPopup {
    p {
      margin: 0;
      padding: 5px 0;
    }
  }
}

.tableHeadingMain {
  display: flex;
  justify-content: space-between;
}

.tableNewButton {
  font-size: 24px;
  span {
    font-size: 16px;
  }
}
