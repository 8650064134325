@import '../../assets/sass/variable.scss';

//Main page styles
.hideStep {
  display: none;
  opacity: 0;
  animation: fade-out 1s 1;
}

.showStep {
  display: block;
  opacity: 1;
  animation: fade-in 0.5s 1;
}

//Payment Type
.paymentType {
  span {
    font-size: 16px;
  }

  > div {
    width: 100%;
  }

  .paymentOption {
    padding: 12px 0;
    border-bottom: 1px solid #e3e4e4;
    &:last-child {
      border: none;
    }
  }
}

//CC form styles
.formItem {
  width: 100% !important;
  margin-bottom: 0px !important;
}

.inputField {
  padding: 6.5px 11px;
  line-height: 19px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.errorInputField {
  color: #fa755a;
  line-height: 19px;
  border-radius: 4px;
  padding: 6.5px 11px;
  border: 1px solid #ff4d4f;
}

//Completed styles
.confirmModelMain {
  text-align: center;
  .body {
    .bodyYan {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 0;
      .bodyYanIcon {
        padding: 14px;
        background-color: $secondaryColor;
        max-width: 24px;
        max-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin-right: 5px;
      }
      p {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .label {
      padding: 0 20px;
    }
  }
  .cancelButton {
    margin-top: 15px;
    > button {
      max-width: 80px;
      margin: 0 !important;
    }
  }
}
.disableTouch {
  pointer-events: none;
}
.cardContent {
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  .cardMain {
    margin-bottom: 10px;
    > div {
      padding: 5px 10px;
      display: flex;
      height: 90px;
    }
    .checkBoxContent {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .cardDetailContent {
      width: 100%;
      margin: 0 10px;
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      .dateContent {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        > div {
          p {
            width: 100%;
          }
          > div:last-child {
            display: flex;
            justify-content: flex-end;
            > div {
              margin-bottom: 0;
            }
          }
          .cardCvcContent {
            height: 30px;
            text-align: right;
            display: flex;
            .cvcContent {
              position: relative;
              width: 60px;
              padding: 6.5px 11px;
              line-height: 19px;
              border-radius: 4px;
              border: 1px solid #d9d9d9;
              > div:last-child {
                > div {
                  margin-bottom: 0px;
                  > div {
                    > div:last-child {
                      position: absolute !important;
                      font-size: 11px;
                      right: -15px;
                      bottom: -30px;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }
}
