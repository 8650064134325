@import '../../assets//sass/variable.scss';
.footer {
  margin-top: 100px;
  header {
    padding: 0;
  }
  footer {
    @media (max-width: 543px) {
      padding: 24px 40px;
    }
  }
}
header {
  @media (max-width: 768px) {
    height: auto !important;
  }
}
.sub {
  display: flex;
  align-items: center;
  @media (max-width: 1025px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 543px) {
    display: block;
  }
  > ul {
    > li {
      @media (max-width: 543px) {
        padding: 0 !important;
      }
    }
  }
}
.logo {
  color: $lightBrown;
  font-size: 34px;
  font-family: 'Times New Roman';
  font-weight: 300;
  letter-spacing: 1px;
  margin-right: 20px;
}
.menu {
  width: auto !important;
  margin-top: 5px;
}

.fortheuture {
  color: $lightBrown;
  font-size: 56px;
  letter-spacing: 3px;
  font-family: 'Times New Roman';
  font-weight: 400;
  @media (max-width: 543px) {
    font-size: 35px;
  }
}
.footerText {
  display: flex;
  line-height: 0.7;
  font-weight: 400;
}
.of {
  color: $lightBrown;
  font-size: 56px;
  letter-spacing: 2px;
  padding-right: 10px;
  font-family: 'Times New Roman';
  @media (max-width: 543px) {
    font-size: 35px;
  }
}

.children {
  color: $lightBrown;
  font-size: 56px;
  letter-spacing: 2px;
  font-family: 'Helvetica Neue';
  @media (max-width: 543px) {
    font-size: 35px;
  }
}

.japanesFooterText {
  color: $lightBrown;
  font-size: 18px;
  letter-spacing: 2px;
  padding-right: 10px;
  margin-top: 40px;
  font-family: 'Noto Sans JP';
  font-weight: 400;
  @media (max-width: 543px) {
    margin-top: 30px;
    font-size: 15px;
  }
}
.copyrightText {
  color: $lightBrown;
  font-size: 10px;
  letter-spacing: 1px;
  padding-right: 10px;
  margin-top: 25px;
  font-family: Helvetica;
  font-weight: 100;
}
