@import '../../assets/sass/variable.scss';
.facility {
  cursor: pointer;
}

.tags {
  color: white;
  border-color: $primaryColor;
  background-color: $primaryColor;
}

.imageContainer {
  position: relative;
}

.facility {
  padding: 16px 16px 0 16px;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
  @media (max-width: 544px) {
    padding: 16px 0 0 0;
  }
  > img {
    border-radius: 8px;
  }
  .image {
    width: 100%;
    height: 248px;
  }
  .facilityDesc {
    bottom: 4%;
    position: absolute;
    padding-left: 2%;
    width: fit-content;
    > span {
      background: $white;
      border-color: $white;
      color: #000;
      text-transform: capitalize;
      font-weight: 600;
      letter-spacing: 3px;
      @media (max-width: 1440px) {
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 2px;
      }
      @media (max-width: 1024px) {
        font-size: 8px;
        line-height: 15px;
        letter-spacing: 1px;
        padding: 0 3px;
      }
    }
  }
  .facilityDescCity {
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
    font-weight: bold;
    border-radius: 8px;
    color: $white !important;
    background-color: $primaryColor;
    @media (max-width: 1440px) {
      font-size: 15px;
      margin-top: 5px;
    }
    @media (max-width: 1024px) {
      font-size: 12px !important;
    }
  }
  .facilityDescPeople {
    font-size: 14px;
    color: $white !important;
    @media (max-width: 1440px) {
      font-size: 11px;
    }
    @media (max-width: 1024px) {
      font-size: 9px;
    }
  }
  .facilityDetail {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .targetAmount {
    margin-top: 10px;
    color: #e0e0e0;
    min-width: 160px;
    @media (max-width: 1024px) {
      min-width: auto;
    }
  }
  .totalEnergyText {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 2px;
    @media (max-width: 1440px) {
      font-size: 11px;
    }
    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }
  .targetAmountDesc {
    display: flex;
    margin-top: 0;
    align-items: center;
  }
  .targetAmountDescIcon {
    display: flex;
    min-width: 30px;
    min-height: 30px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: $secondaryColor;
    @media (max-width: 1440px) {
      min-height: 27px;
      min-width: 27px;
    }
    @media (max-width: 1024px) {
      min-height: 20px;
      min-width: 20px;
    }
  }
  .targetAmountDescPrice {
    padding: 0 8px;
    font-size: 24px;
    color: $darkText;
    font-family: Roboto;
    @media (max-width: 1440px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
  .progress {
    width: 100%;
    margin-top: 10px;
    div {
      div {
        @media (max-width: 1024px) {
          height: 15px !important;
          span {
            font-size: 11px !important;
          }
        }
      }
    }
  }
  .people {
    display: flex;
    margin-top: 10px;
    align-items: center;
    @media (max-width: 1024px) {
      margin-top: 5px;
    }
  }
  .peopleCity {
    color: $offWhite;
    padding: 0 2px 0 0;
    font-family: sans-serif;
    font-size: 22px !important;
  }
  .peopleChildren {
    padding: 0 3px;
    color: $darkText;
    font-weight: 24;
    font-weight: 500;
    font-family: Roboto;
    font-size: 22px !important;
    @media (max-width: 1440px) {
      font-size: 17px !important;
    }
    @media (max-width: 1024px) {
      font-size: 15px !important;
    }
  }
  .peopleText {
    color: $gray;
    font-size: 12px;
    font-family: sans-serif;
    @media (max-width: 1440px) {
      font-size: 12px;
    }
    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }
  .facilityList {
    display: flex;
    display: flex;
    padding: 19px 30px 3px;
  }

  .image {
    overflow: hidden;
    border-radius: 10px;
  }
  .tag {
    position: absolute;
    top: 25px;
    left: 25px;
  }
  .totalSupportEnergyText {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7d7d7d;
    font-size: larger;
    span {
      margin-left: 5px;
    }
  }
  .childrenMain {
    display: flex;
    height: 50px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    font-size: larger;
  }
  .color0 {
    background-color: rgba(86, 252, 197, 0.8);
  }
  .color1 {
    background-color: rgba(81, 191, 203, 0.8);
  }
  .color2 {
    background-color: rgba(206, 83, 83, 0.8);
  }
  .color3 {
    background-color: rgba(25, 122, 186, 0.8);
  }
  .color4 {
    background-color: rgba(82, 82, 223, 0.58);
  }
  .color5 {
    background-color: rgba(208, 47, 136, 0.8);
  }
  .color6 {
    background-color: rgba(237, 122, 149, 0.8);
  }
  .color7 {
    background-color: rgba(212, 222, 24, 0.8);
  }
  .color8 {
    background-color: rgba(164, 115, 220, 0.8);
  }
  .color9 {
    background-color: rgba(212, 174, 237, 0.8);
  }
}
