@import '../../../../assets/sass/variable.scss';
.container {
  margin: 25px auto;
}
.memberTable {
  overflow: hidden;
  background-color: $white !important;
  padding: 15px;
  border: 1px solid $lightGray;
  border-radius: 10px;
  margin-bottom: 50px;

  th {
    background-color: $white !important;
  }

  .search {
    margin: 10px 4px;
    .searchBox {
      margin-left: 15px;
      overflow: hidden;
    }
  }
  .select {
    display: flex;
    .memberListSelect {
      > div {
        width: 120px;
        height: 40px !important;
        display: flex;
        align-items: center;
      }
    }
  }
  .messageBtn {
    margin-left: 8px;
  }
}

.memberTableColumn {
  display: flex;
  cursor: pointer;
  .title {
    margin-top: 15px;
    margin-left: 15px;
    p {
      margin-bottom: 0em !important;
    }
  }
  .usrImg {
    border-radius: 30px;
    overflow: hidden;
  }
  p {
    margin-bottom: 0em !important;
  }
}
