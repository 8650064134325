@import '../../assets/sass/variable.scss';

.facilitiesDetail {
  width: 100%;
  position: relative;
  background-color: #f7f6f0;
  > div {
    padding: 0 20px;
  }
  > div:first-child {
    padding: 0;
  }
  > div:last-child {
    padding: 0;
  }
  img {
    height: 150px;
  }

  .reportTitle {
    width: 100%;
    font-size: 18px;
    color: #7d7d7d;
  }

  .cityName {
    font-size: 14px;
    padding: 2px 12px;
    background-color: $secondaryColor;
  }

  .facilityArea {
    position: absolute;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    top: 130px;
    background-color: #f7f6f0;

    .facilityTitle {
      display: flex;
      font-size: 12px;
      font-weight: 700;
      margin-top: 20px;

      p {
        font-weight: 400;
        margin: 0 5px 0 0;
      }
    }
  }

  .facilityInfo {
    margin-top: 75px; //120px
  }

  .facilityName {
    margin-bottom: 0;
  }

  .facilityText {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    color: $primaryColor;
  }

  .progress {
    width: 100%;
  }
  .bookmarkBtn {
    margin: 16px 0px;
    display: flex;
    align-items: center;
    background-color: transparent;
    svg {
      margin-left: 10px;
    }
  }
  .followerContent {
    margin-top: 16px;
    display: flex;
    > div {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;

      p {
        margin: 0 0 0 5px;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
      }
    }
    > div:last-child {
      margin-left: 0px; //27px
    }
  }
  .dividerContent {
    border-top: 1px solid $secondaryColor;
    margin: 16px 20px;
  }
  .energyContent {
    > div {
      width: 50%;
    }
  }
  .facilityDetailsTab {
    > div:first-child {
      > div {
        > div {
          padding: 0 10px;
        }
      }
    }
    img {
      border-radius: 8px;
      height: 200px;
    }
    .missionContent {
      padding: 0 16px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
