@import '../../assets/sass/variable.scss';

.totalAmountBox {
  > div {
    padding: 20px !important;
  }
  .totalAmountTitle {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.totalAmountHistoryCard {
  > div {
    padding: 30px !important;
  }
  .historyDate {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    color: $gray;
  }
}
.energyList {
  .enegrySymbol {
    > div {
      > div {
        > span {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .listTitle {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
  }
}
.totalEnergyMain {
  display: flex !important;
  justify-content: center;
  > div {
    border-bottom: 2px solid $lightBrown;
    width: 231px;
    display: flex;
    padding-bottom: 8px;
    justify-content: center;
    > div {
      display: flex;
      align-items: center;
    }
  }
}
.mobileCard {
  margin-top: 30px;
  > h4 {
    padding-left: 22px;
    font-size: 18px !important;
  }
  > div {
    border: 0px;
    .historyDate {
      padding-left: 22px;
      font-size: 12px !important;
      font-weight: 400 !important;
    }
  }
  .totalAmountHistoryCard {
    background-color: transparent !important;
    border: 0px !important;
    > div {
      border: 0px;
      .historyDate {
        padding-left: 22px;
        font-size: 12px !important;
        font-weight: 400 !important;
      }
    }
    > div {
      padding: 0px !important;
      background-color: transparent !important;
    }
    .historyDate {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.17px;
      color: $gray;
    }
  }
  .listMainContentMobile {
    li {
      padding: 12px 22px;
    }
  }
}
.totalAmountTitleMobile {
  margin-top: 26px;
  margin-bottom: 29px;
  font-size: 18px;
  font-weight: 500;
}
