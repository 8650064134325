@import '../../../assets/sass/variable.scss';

.headingTitle {
  font-size: 40px;
  font-weight: 700;
  padding: 0 20px;
  font-family: 'Times New Roman';
  @media (max-width: 544px) {
    font-size: 27px;
    display: block;
    padding: 0;
    margin-top: 30px;
  }
}
.facilitiesMain {
  margin-top: 50px;
}
.topTab {
  > div {
    > div {
      > div {
        > div {
          > div {
            > div {
              display: block;
              width: 100%;
              div {
                width: 100%;
                display: block;
                text-align: center;
                div {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
.tabMain {
  padding: 0 20px;
  width: fit-content;
  @media (max-width: 769px) {
    padding: 0;
  }
  > div {
    > div {
      > div {
        > div {
          div {
            @media (min-width: 767px) {
              width: 150px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.headingSubtitle {
  color: $gray;
  font-size: 16px;
  font-family: 'Times New Roman';
  @media (max-width: 544px) {
    color: $gray;
  }
}
.bannerMain {
  position: relative;
  background: $primaryColor;
  height: auto;
  @media (max-width: 544px) {
    height: auto;
    background: $primaryColor;
  }
  .bannerLeft {
    position: absolute;
    left: 10%;
    transform: translateY(-50%);
    top: 50%;
    @media (max-width: 544px) {
      right: 10%;
    }
    .logo {
      font-size: 72px;
      font-family: 'Times New Roman';
      letter-spacing: 0.02em;
      color: #e6e2cf;
      font-weight: normal;
      margin-bottom: 0.5rem;
      height: 50px;
      display: flex;
      @media (max-width: 1024px) {
        align-items: center;
        height: 40px;
        font-size: 60px;
      }
    }
    .subTitle {
      font-family: 'Noto Sans JP';
      font-size: 24px;
      letter-spacing: 0.1rem;
      color: #e6e2cf;
      font-weight: 400;
      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }
    .desc {
      font-size: 16px;
      letter-spacing: 0.05em;
      color: #e6e2cf;
      max-width: 425px;
      margin: 45px 0;
      @media (max-width: 1024px) {
        margin: 18px 0;
        font-size: 13px;
        max-width: 325px;
      }
      @media (max-width: 768px) {
        margin: 20px 0;
      }
    }
    .banerBtn {
      font-family: Roboto;
      background: #a6966d;
      padding: 22px;
      font-size: 20px;
      font-weight: 500;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: none;
      @media (max-width: 1024px) {
        padding: 18px;
        font-size: 17px;
      }
      @media (max-width: 544px) {
        width: 100%;
      }
    }
  }
}
