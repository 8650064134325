.cardBox {
  min-height: 100vh;
  background-color: #e5e5e5;
  flex-direction: column !important;
}
.companyName {
  font-size: 48px;
  font-weight: bold;
  font-family: Times;
  margin-top: -80px;
  margin-bottom: 20px;
}

.card {
  width: 400px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(0, 0, 0, 0.12);
}

.divider {
  margin: 12px 0px !important;
}

.subtitle {
  margin-bottom: 24px !important;
}
.form {
  width: 100%;
  &-input-link {
    text-align: right;
    margin-top: -24px !important;
  }
  &-link {
    text-align: right;
  }
}
