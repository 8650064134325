.mobileSupportAmount {
  margin: 24px 0px;

  .carouselItem {
    margin-left: 5px;
    margin-right: 5px;
  }
  .infoContent {
    padding: 0 20px;
    .info {
      font-size: 14px;
      margin: 36px 0px 0px 0px;

      .ownedEnergy {
        color: #7d7d7d;
      }

      .charge {
        a {
          color: #0c7ff2;
        }
      }
    }

    .divider {
      margin: 0px 12px 0px 0px;
      background-color: #cec5b9;
    }
  }
}
