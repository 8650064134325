@import '../../assets/sass/variable.scss';

.storyContent {
  width: fit-content;
}
.mobileContent {
  p {
    margin-bottom: 0;
  }
  .profileText {
    margin-top: 20px !important;
    margin-bottom: 16px !important;
    color: $gray;
  }
  .profileImage {
    img {
      height: 100% !important;
    }
  }
}
