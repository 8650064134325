@import '../../../src/assets/sass/variable.scss';
.card {
  margin-bottom: 10px;
  .cardMain {
    width: 60%;
   
    .cardDetailContent {
      p {
        margin: 0;
        font-size: 16px;
      }
     
    }
  }
  
  .deleteIcon {
    cursor: pointer;
  }
}
