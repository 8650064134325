.verifyBtn {
  margin-left: 10px;
}
.btnContent {
  display: flex;
  button {
    margin-right: 10px;
  }
}

.iconContent {
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
}
.unverifiedContent {
  display: flex;
  align-items: center;
}
