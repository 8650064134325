@import '../../../../assets/sass/variable.scss';

.supportTitle {
  color: $darkText;
  padding: 40px 0px;
  @media (max-width: 544px) {
    padding: 40px 0px;
  }
}
.rankingTable {
  @media (max-width: 425px) {
    overflow-x: scroll;
    div {
      overflow-x: scroll;
      width: 100%;
    }
    th,
    td {
      &:nth-child(1) {
        min-width: 110px;
      }
      &:nth-child(2) {
        min-width: 200px;
        font-size: 16px;
        font-weight: 700;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        min-width: 120px;
        // display: flex;
        span {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  table {
    border-radius: 10px !important;
    th {
      background-color: $primaryColor !important;
      color: $white !important;
      &:nth-child(1) {
        border-top-left-radius: 8px !important;
      }
      &:nth-child(6) {
        border-top-right-radius: 8px !important;
      }
    }
    td {
      span {
        font-size: 16px;
        font-weight: 500;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }
    tbody {
      tr {
        &:last-child {
          td:first-child {
            border-bottom-left-radius: 8px !important;
          }
          td:last-child {
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }
  }
}

.usernameContent {
  display: flex;
  align-items: center !important;
  p {
    margin: 0;
    padding-left: 10px;
  }
}
