@import '../../../src/assets/sass/variable.scss';

.paymentModal {
  .header {
    flex-direction: column;

    .supportType {
      font-size: 18px;
      color: #312b2b;
      font-weight: bold;
    }

    .paymentAmount {
      color: $gray;
    }
  }

  textarea {
    border-radius: 10px;
    background: #eeeeee;
  }
  .descriptionComment {
    display: block;
  }
}
