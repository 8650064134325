@import '../../../../../assets/sass/variable.scss';

.mr10 {
  margin: 10;
}
.posts {
  margin: 0 auto !important;
  .view {
    margin-bottom: 20px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
      0px 1px 2px rgba(0, 0, 0, 0.12);
    .header {
      display: flex;
      .usrImg {
        margin-top: 3px;
      }
      .username {
        margin-left: 10px;
      }
    }
    .tag {
      margin-bottom: 20px;
      margin-top: 10px;
      padding-left: 0 !important;
      .tagInner {
        padding: 2px 13px;
        border-radius: 20px;
      }
    }
    .flexDiv {
      display: flex;
    }
    .mr3 {
      margin: 3;
    }

    .menuIcon {
      margin-top: 10px;
    }

    .description {
      font-size: 14px;
      letter-spacing: 0.5px;
      color: $lightText;
      padding-right: 20px;
    }
    .likeTitle {
      color: $lightText;
      padding-left: 5px;
      margin-right: 15px;
    }
  }

  .pagination {
    text-align: center;
  }
}
.redLike {
  color: rgb(245, 69, 78) !important;
}
