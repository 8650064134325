.selectedContainer {
  width: 187px;
  height: 230px;
  color: #f7f6f0;
  position: relative;
  letter-spacing: 0.03em;
  flex-direction: column;
  font-family: Times New Roman;

  .backgroundImg {
    z-index: -1;
    position: absolute;
  }

  .supportAmountBox {
    height: 100%;
    padding: 14px;
    flex-direction: column;
  }

  .title {
    font-size: 14px;
  }

  .amount {
    font-size: 48px;
    line-height: 40px;
  }

  .text {
    font-size: 18px;
  }
}

.unSelectedContainer {
  width: 150px;
  height: 184px;
  padding: 12px;
  opacity: 0.6;
  color: #f7f6f0;
  border-radius: 28px;
  flex-direction: column;
  background: linear-gradient(90deg, #12221c 0%, #17513c 100%);
  font-family: Times New Roman;

  .title {
    font-size: 12px;
  }

  .amount {
    font-size: 32px;
    line-height: 32px;
  }

  .text {
    font-size: 18px;
  }
}
