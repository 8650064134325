@import '../../../src/assets/sass/variable.scss';
.mainCard {
  position: relative;
  padding: 10px 0px;
  @media (max-width: 425px) {
    background-color: transparent !important;
  }

  > div {
    display: flex;
    justify-content: space-between;
    padding: 24px !important;
    @media (max-width: 425px) {
      padding: 16px 0px !important;
    }
  }
  .subFirstContent {
    padding-top: 24px;
    @media (max-width: 425px) {
      padding: 0 !important;
    }
  }
  .subThirdContent {
    padding-top: 46px;
    @media (max-width: 425px) {
      position: absolute;
      right: 0px;
      padding: 0;

      button {
        background-color: transparent !important;
      }
    }
  }
  div:nth-child(2) {
    margin-left: 10px;
    padding: 0px 15px;
    @media (max-width: 425px) {
      padding: 0px;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: $gray;
    }
    p:nth-child(3) {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .titleContent {
    display: flex;
    align-items: center;
    margin-bottom: -3px;
    p {
      margin: 0 5px 0 0 !important;
      font-size: 16px !important;
      font-weight: 700 !important;
      color: $black !important;
    }
  }
}
