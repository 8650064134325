@import '../../assets/sass/variable.scss';

.possessionValue {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .possessionValueSymbol {
    width: 40px;
    height: 40px;
    @media (max-width: 575px) {
      width: 24px;
      height: 24px;
    }
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background: $secondaryColor;
    border: 1px solid $secondaryColor;
  }
  .possessionValueLabel {
    font-size: 20px;
    @media (max-width: 575px) {
      font-size: 14px;
    }
    color: $primaryColor;
    font-family: 'Times New Roman';
  }
}
