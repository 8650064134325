@import '../../../../../assets/sass/variable.scss';
.container {
  margin: 25px auto;
}
.inquiryMessage {
  overflow: hidden;
  background-color: $white !important;
  padding: 0;
  border: 1px solid $lightGray;
  border-radius: 10px;
  margin-bottom: 50px;
}
.headerStyle {
  padding: 20px;
  border-bottom: 1px solid #e2e2e2;
}
.dFx {
  display: flex;
  align-items: center;
}
.backArrow {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.userImage {
  border-radius: 30px;
  overflow: hidden;
  margin-left: 10px;
}
.profile {
  width: 40px;
}

.userName {
  margin-left: 10px;
  p {
    margin: 0;
  }
}
.boldUserName {
  font-size: 14px;
  font-weight: bold;
}
.userTitle {
  font-size: 11px;
  font-weight: 200;
}

.messageDate {
  display: flex;
  align-items: center;
  margin-top: 12px;
  p {
    font-size: x-small;
    margin: 0;
    margin-left: -30px;
  }
}
.leftArrow {
  font-size: x-small;
  margin-left: 20px;
  display: flex;
  cursor: pointer;
}
.rightArrow {
  font-size: x-small;
  margin-left: 30px;
  display: flex;
  cursor: pointer;
}
.ellipsisLine {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sendBack {
  display: flex;
  border-top: 1px solid $lightGray;
}
.innerSendBack {
  width: 93%;
  display: flex;
  > div:first-child {
    margin: 0;
    width: 100%;
    > div {
      display: contents;
      > div {
        width: 100%;
      }
      input {
        padding: 10px;
        border: none;
        width: 100%;
        &:focus {
          border: 0;
          box-shadow: none;
        }
      }
    }
  }
}
.upload {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 0px;
  width: 13% !important;
  background-color: $white;
}
.paperClip {
  margin: 0 12px 0 25px;
}
.sendIcon {
  width: 7%;
  border-left: 1px solid $lightGray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  button {
    background-color: transparent !important;
    border: 0;
    box-shadow: none;
  }
}
.detailDesc {
  padding: 20px;
}

.deleteContent {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  p:nth-child(2) {
    margin: 0;
  }
}
