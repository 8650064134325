.hide {
  display: none !important;
}

.cropContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 52px;
}
.roundCropper {
  > div {
    > div:nth-child(3) {
      border-radius: 50% !important;
      > span:nth-child(1) {
        border-radius: 50% !important;
      }
    }
  }
}
