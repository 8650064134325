@import '../../assets/sass/variable.scss';
.personalInfo {
  padding: 0 70px;
  margin-bottom: 50px;
  @media (max-width: 425px) {
    padding: 0 !important;
    > div {
      border-radius: 0 !important;
    }
  }
  .card {
    > div {
      padding: 30px;
      overflow-x: hidden;
    }
    .formSection {
      margin-top: 50px;
    }
  }

  .heading {
    font-size: 15px;
    font-weight: 700;
    position: relative;
    letter-spacing: 1px;
    &::after {
      position: absolute;
      content: '';
      bottom: -20px;
      left: -40px;
      width: 110%;
      height: 2px;
      background: $lightGray;
    }
  }
}
