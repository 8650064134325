@import '../../../../../assets/sass/variable.scss';

.userInformation {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(0, 0, 0, 0.12);
}

.input {
  display: flex;
}
