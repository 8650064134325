@import '../../../src/assets/sass/variable.scss';
.mainSearch {
  padding: 20px;
}

.supportMain {
  margin-bottom: 20px;

  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 2px 2px $secondaryColor;

  @media (max-width: 425px) {
    width: 100% !important;
    max-width: 100%;
    background-color: transparent;
    box-shadow: none;
  }
  .imageMain {
    position: relative;

    .image {
      height: 186px;
      width: 100% !important;
      border-radius: 8px 8px 0 0;
      object-fit: cover !important;
      @media (max-width: 425px) {
        border-radius: 8px;
      }
    }
    .imageContent {
      position: absolute !important;
      bottom: 0px;
      padding: 14px;
      h3 {
        font-size: 24px;
        font-weight: 700;
        color: $white;
        margin-top: 10px;
        margin-bottom: 4px;
      }
      .memberContent {
        span {
          font-size: 14px;
          font-weight: 400;
          color: $white;
        }
      }
    }
  }
  .subContent {
    padding: 10px 14px !important;
  }
  .subCardContent {
    display: flex;
    > div:nth-child(2) {
      margin-left: 8px;
    }
  }
  .facilityDetail {
    display: flex;
  }
  .targetAmount {
    margin-top: 10px;
    color: $lightGray;
    min-width: 160px;
    @media (max-width: 1024px) {
      min-width: auto;
    }
  }
  .totalEnergyText {
    color: $gray;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 2px;
  }
  .targetAmountDesc {
    display: flex;
    margin-top: 0;
    align-items: center;
  }
  .targetAmountDescIcon {
    display: flex;
    min-width: 30px;
    min-height: 30px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: $secondaryColor;
  }
  .targetAmountDescPrice {
    span {
      padding: 0 4px;
      font-size: 24px !important;
      font-weight: 400 !important;
      color: $black !important;
      font-family: Roboto !important;
      @media (max-width: 320px) {
        font-size: 20px !important;
      }
    }
  }
  .progress {
    width: 100% !important;
    margin-top: 10px;
    position: relative;
    div {
      div {
        padding: 0;
        div {
          background-color: $lightGray;
          div {
            height: 24px !important;
            background-color: $primaryColor;
          }
        }
      }
      span {
        position: absolute;
        left: 15px;
        top: 3px;
        color: $white;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .bottomContent {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      font-size: 18px;
      font-weight: 400;
      color: #000;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: $gray;
      margin: 0;
      margin-left: 5px;
    }
  }
}
