@import '../../../assets/sass/variable.scss';

.accountSetting {
  margin: 50px 0;

  .Accountheading {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .accountTabMain {
    padding-right: 30px;
    .accountSettingTabs {
      padding: 6px 10px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      cursor: pointer;
    }
    .active {
      background: $secondaryColor;
    }
    @media (max-width: 425px) {
      display: flex;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .accountSettingTabs {
        padding: 6px 10px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        word-break: keep-all;
        color: $gray;
        > div {
          border: 2px solid;
        }
      }
      .active {
        background: transparent;
        color: $black;
      }
    }
  }
}
