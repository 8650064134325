@import '../../../src/assets/sass/variable.scss';

.supportAmount {
  height: 120px;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  border-radius: 8px;
  padding: 22px 10px;
  background-size: cover;
  background-image: none;
  background: transparent;
  border: 1px solid $lightGray;
  background-repeat: no-repeat;
  justify-content: space-around;
  .supportAmountEnergy {
    text-align: center;
  }
  .supportAmountEnergyText {
    margin: 0 10px;
  }
  .checkCircle {
    font-size: 22px !important;
  }
}

.supportAmountActive {
  color: $white;
  background: #142d24;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #142d24;
  background-image: url(../../assets/images/support-back.png);
}
