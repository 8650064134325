.form {
  width: 100%;
  .or {
    margin-right: 5px;
    display: inline-block;
  }
  .resetLink {
    text-align: right;
    margin-top: -24px !important;
  }
}
