@import '../../../../assets/sass/variable.scss';

.message {
  width: 100%;
  .table {
    padding: 10px 17px 0 17px;
    background-color: $white;

    border: 1px solid $lightGray;
    border-radius: 10px;
    th {
      background-color: $white !important;
    }
  }
}
