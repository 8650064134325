@import '../../assets/sass/variable.scss';

.energyChargeBox {
  width: 100%;
  cursor: pointer;
  background: $white;
  padding: 35px 25px;

  @media (max-width: 544px) {
    padding: 20px 15px;
  }
  @media (max-width: 320px) {
    width: 290px;
    padding: 20px 5px;
  }
  border-radius: 9px;
  border: 1px solid $secondaryColor;

  .stat {
    height: 100%;
  }

  .energySymbol {
    width: 40px;
    height: 40px;
    @media (max-width: 544px) {
      width: 24px;
      height: 24px;
    }
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background: $secondaryColor;
    border: 1px solid $secondaryColor;
  }

  .energyText {
    font-family: 'Times New Roman';
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }

  .amountBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .amount {
      font-weight: 600;
      padding: 2px 12px;
      border-radius: 5px;
      background: $offWhite;
    }
  }
}
