@import '../../../assets/sass/variable.scss';

.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.textIndent {
  text-indent: '20px';
}
.textTitle {
  font-weight: 700;
  font-size: 20px;
}
.text1 {
  margin-left: 20px;
}
.mainCol {
  > div {
    > div {
      padding: 0 10px;
    }
  }
  .tabMain {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .tabTitle {
      font-weight: 500;
      font-size: 16px;
      color: #142d24;
      opacity: 0.4;
      width: max-content;
      cursor: pointer;
    }
    .activeTabTitle {
      border-bottom: 2px solid $primaryColor;
      opacity: 1;
      font-weight: 500;
      font-size: 16px;
      color: #142d24;
      width: max-content;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
}

.titleContent {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    justify-content: start;
    padding-left: 20px;
  }
  p {
    font-size: 24px;
    font-weight: 900;
  }
}
.tableMainContent {
  table {
    border: 1px solid #f0f0f0;

    thead {
      display: none;
    }
    tbody {
      tr {
        p {
          margin: 0;
          padding: 13px 0px;
          font-size: 14px;
          @media (max-width: 425px) {
            padding: 7px 0px;
            font-size: 12px;
          }
        }

        td:first-child {
          background-color: #fcfcfc;
          border-right: 1px solid #f0f0f0;
          font-weight: 700;
          text-transform: uppercase;
        }
        td:last-child {
          font-weight: 400;
        }
      }
    }
  }
}
.cardContent {
  > div {
    padding: 24px;
  }
}
.noDataContent {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableValue {
  display: flex;

  .fromHereContent {
    color: #1662dd;
    margin-right: 5px;
    cursor: pointer;
  }
}
