@import '../../../../assets/sass/variable.scss';

.tableHeadingMain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tableNewButton {
  font-size: 24px;
  span {
    font-size: 16px;
  }
}
.menuContent {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
  .dropdownContent {
    margin: 0 10px;
    cursor: pointer;
    width: 133px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
  .dropdownContent2 {
    margin: 0 10px;
    cursor: pointer;
    height: 50px;
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
}
