@import '../../../assets/sass/variable.scss';

.invitesTitle {
  margin: 0 !important;
}
.descMain {
  margin-bottom: 15px;
}
.invitesDesc {
  color: $gray;
  margin-bottom: 15px;
}
