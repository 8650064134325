@import '../../../src/assets/sass/variable.scss';
.mainContent {
  width: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.mainUser {
  padding: 3px 3px 1px 3px;
  width: fit-content;
  border-radius: 50px;
  position: relative;
  margin-right: 20px;
  margin-top: 5px;
  .userPlusIcon {
    position: absolute;
    bottom: 3px;
    right: 2px;
    background-color: $primaryColor;
    color: $white;
    border-radius: 25px;
    font-size: 20px;
  }
}

.activeUserName {
  text-align: center;
}
.activeUser {
  border: 5px solid $primaryColor;
  padding: 3px;
  width: fit-content;
  border-radius: 50px;
  margin-right: 20px;
}
.seenUser {
  border: 5px solid $gray;
  padding: 3px;
  width: fit-content;
  border-radius: 50px;
  margin-right: 20px;
}
.userStory {
  display: flex;
}
