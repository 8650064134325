@import '../../../src/assets/sass/variable.scss';
.planMainBox {
  display: flex;
  justify-content: space-between;
  border: 1px solid $lightGray;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  .planBox {
    display: flex;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
    p {
      margin-bottom: 0;
      margin-left: 5px;
      font-family: Times New Roman;
      font-size: 20px;
      font-weight: 400;
    }
  }
  .planSecContent {
    p {
      margin-bottom: 0;
    }
    p:nth-child(1) {
      font-size: 16px;
      font-weight: 700;
    }
    p:nth-child(2) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      > p {
        font-size: 16px;
        font-weight: 400;
        color: $gray;
        margin-left: 2px;
      }
    }
  }
}
