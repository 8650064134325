.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.textIndent {
  text-indent: '20px';
}
.mainCol {
  > div {
    > div {
      padding: 0 10px;
    }
  }
  .tabMain {
    div:nth-child(2) {
      padding: 0;
    }
  }
}
