@import './assets/sass/variable.scss';

body {
  margin: 0;
  background-color: #f7f6f0;
}
container {
  max-width: 1230px;
  margin: 25px auto;
}

.space-align-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

pageStyles {
  color: '#232129';
  padding: 96;
  font-family: '-apple-system, Roboto, sans-serif, serif';
}

headingStyles {
  margin-top: 0;
  margin-bottom: 64;
  max-width: 320;
}

headingAccentStyles {
  color: '#663399';
}

paragraphStyles {
  margin-bottom: 48;
}

codeStyles {
  color: '#8A6534';
  padding: 4;
  background-color: '#FFF4DB';
  font-size: '1.25rem';
  border-radius: 4;
}

listStyles {
  margin-bottom: 96;
  padding-left: 0;
}

listItemStyles {
  font-weight: 300;
  font-size: 24;
  max-width: 560;
  margin-bottom: 30;
}

linkStyle {
  color: '#8954A8';
  font-weight: 'bold';
  font-size: 16;
  vertical-align: '5%';
}

docLinkStyle {
  list-style-type: 'none';
  margin-bottom: 24;
}

descriptionStyle {
  color: '#232129';
  font-size: 14;
  margin-top: 10;
  margin-bottom: 0;
  line-height: 1.25;
}

badgeStyle {
  color: '#fff';
  background-color: '#088413';
  border: '1px solid #088413';
  font-size: 11;
  font-weight: 'bold';
  letter-spacing: 1;
  border-radius: 4;
  padding: '4px 6px';
  display: 'inline-block';
  position: 'relative';
  top: -2;
  margin-left: 1;
  line-height: 1;
}
