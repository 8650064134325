@import '../../../../../assets/sass/variable.scss';

.message {
  .tags {
    border: 1px solid $lightGray;
    padding: 5px;
    padding-top: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    min-height: 45px;
    .tagStyle {
      margin-bottom: 10px;
    }
  }
  .tagContent {
    margin: 0 0 5px 5px;
  }
  .form {
    margin-bottom: 15px;
  }
}
