@import '../../../../assets/sass/variable.scss';
.mainDiv {
  margin: 20px 0;
  .inputAns {
    margin: 10px 0;
  }
  .noteLable {
    font-size: 18px;
    font-weight: 500;
  }
  .note {
    font-size: 14px;
    font-weight: 400;
    color: $gray;
  }
}
.inputDiv {
  > div {
    display: block;
  }
  input {
    max-width: 225px;
  }
}
.keepBtn {
  width: fit-content;
  display: flex;
  button {
    border-radius: 4px !important;
    text-transform: uppercase;
    margin-right: 10px;
  }
}
.heading {
  font-size: 15px;
  font-weight: 700;
  position: relative;
  letter-spacing: 1px;
  &::after {
    position: absolute;
    content: '';
    bottom: -20px;
    left: -40px;
    width: 110%;
    height: 2px;
    background: $lightGray;
  }
}
