@import './../../../../assets/sass/variable.scss';

.breadcrumb {
  margin: 15px 0;
}
.memberTab {
  padding: 20px 0 50px 0;
}
.memberTitle {
  margin: 20px 0 15px 0;
  font-size: 24px;
  font-weight: bold;

  .flexDiv {
    display: flex;
  }
  .userImage {
    margin-top: 10px;
  }
  .rankTag {
    font-size: 12px;
    background-color: $primaryColor;
    padding: 0px 15px 0 15px;
    border-radius: 25px;
    height: 15px;
    margin-left: 10px;
    color: $white;
    margin-top: 6px;
  }
  .levelLabel {
    font-size: 14px;
    padding-top: 5px;
    font-weight: 500;
    margin-right: 5px;
    opacity: 0.5;
  }

  .userIdName {
    font-size: 14px;
    padding-top: 5px;
    font-weight: 500;
    margin-left: 10px;
    opacity: 0.5;
  }

  .totalAmountSupport {
    display: flex;
    .label {
      font-size: 14px;
      padding-top: 5px;
      font-weight: 500;
      margin-left: 5px;
      opacity: 0.5;
    }
  }
}
