@import '../../../src/assets/sass/variable.scss';

.commentedTitleContent {
  margin-left: 16px;

  align-items: center;
  > div {
    h4 {
      font-size: 16px;
      font-weight: 700;
      margin: 0 5px 0 0;
    }
    display: flex;
    align-items: center;
  }
  p {
    font-size: 12px;
    font-weight: 900;
    color: #999;
  }

  @media (max-width: 425px) {
    display: none;
  }
}
.commentFirstContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.userDetailsContent {
  margin: 20px 0;
  display: flex;
}
.commentedUserDetailsContent {
  @media (max-width: 425px) {
    display: flex;
    align-items: flex-end;
    padding-bottom: 25px;
  }
  img {
    width: 56px;
    height: 56px;
    border-radius: 50px;
    object-fit: cover;
  }
}
.commentedSubUserDetailsContent {
  img {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    object-fit: cover;
  }
}
.commentDescription {
  margin-left: 16px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 8px;
  width: fit-content;
  @media (max-width: 425px) {
    background-color: #fff !important;
    margin: 0px 12px;
  }
  font-size: 12px;
  font-weight: 400;
}
.commentDate {
  display: flex;
  align-items: center;
  p {
    font-size: 10px;
    font-weight: 400;
    color: #7d7d7d;
    margin-left: 16px;
  }
  .dateContent {
    padding-top: 10px;
  }

  .commentFirstContent {
    display: flex;
    width: fit-content;
    @media (max-width: 425px) {
      justify-content: space-between;
      width: 100%;
      padding-right: 12px;
    }
  }
  .commentSecondContent {
    display: flex;
    @media (max-width: 425px) {
      padding-left: 6px;
      margin-top: -10px;
    }
    .replyContent {
      font-size: 13px;
      margin: 0 0 0 10px;
      cursor: pointer;
    }
    p {
      font-size: 10px;
      color: #7d7d7d;
      margin-left: 16px;
    }
  }
  @media (max-width: 425px) {
    display: block;
  }
}
.commentedBox {
  background-color: $white;
  padding: 15px;
  width: fit-content;
  border-radius: 8px;
}
.hiddenBox {
  width: 100%;
  > div {
    > div {
      > div:first-child {
        display: none !important;
      }
    }
  }

  .ant-form-item-control-input {
    display: none !important;
  }
}

.inputBox {
  width: 100%;
  padding-left: 10%;
  margin-bottom: 15px;
}
.commentUserContent {
  width: 100%;
  justify-content: space-around;
  .dotContent {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.popoverButton {
  cursor: pointer;
  padding: 5px;
  width: fit-content;
}
.likeIcon {
  font-size: 18px;
  margin-left: 10px;
  margin-top: 8px;
}
.likeRedIcon {
  font-size: 18px;
  margin-left: 10px;
  margin-top: 8px;
  svg {
    fill: rgb(245, 69, 78);
  }
}
