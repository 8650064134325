@import '../../../src/assets/sass/variable.scss';

.options {
  cursor: pointer;
  font-size: 16px;
  margin: 0 0 10px 0px;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;

  .sowSeed {
    background-color: $lightGreen;
  }
  .giveWater {
    background-color: $cyan;
  }
  .pourLight {
    background-color: $darkPink;
  }
  .cardDesign {
    padding: 15px 15px;
    border: 1px solid $lightGray;
  }
}
.cardHeader {
  display: flex;
  justify-content: space-between;
  .cardHeaderAvatar {
    display: flex;
    .supporterName {
      margin: 0 10px;
      font-size: 14px;
      font-weight: 400;
      display: flex !important;
      flex-direction: column !important;

      .whiteText {
        color: $white;
        margin-bottom: -4px;
      }
      .blackText {
        color: $black;
        margin-bottom: -4px;
      }
    }
    .supportTypeIcon {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
  }
}

.supporterComments {
  margin: 10px 0 0 0;
  font-size: 12px;
  .whiteText {
    color: #ffffff;
  }
  .blackText {
    color: #000000;
  }
}
