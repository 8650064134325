@import '../../../../assets/sass/variable.scss'; 
.dateContent {
  > div:last-child {
    > div {
      > div {
        > div {
          width: 100%;
          height: 40px;
        }
      }
    }

    input {
      width: 100%;
    }
  }
}

.message {
  width: 100%;
  .table {
    padding: 10px 17px 0 17px;
    background-color: $white;
    border: 1px solid $lightGray;
    border-radius: 10px;
    th {
      background-color: $white !important;
    }
  }
}
.popoverButton {
  margin: 0;
  cursor: pointer;
  padding: 5px;
}
.btnContent {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; 
 
}
