//user Story
.userStoryContent {
  margin-top: 20px;
  > div {
    display: flex;
  }
}
.blankContent {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.postMainContent {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  .tagMain {
    display: flex;
  }
}
.modalContent {
  position: relative;
  .btnContent {
    display: flex;
    justify-content: flex-end;
    button {
      width: fit-content !important;
    }
  }
  .moreContent {
    font-size: 25px;
    color: white;
    position: absolute;
    top: 25px;
    right: 5px;
    z-index: 999;
  }
  .moreContent1 {
    font-size: 25px;
    color: white;
    position: absolute;
    top: 75px;
    right: 5px;
    z-index: 999;
  }
}
.actionContent {
  p {
    width: 100%;
    cursor: pointer;
    margin-bottom: 0;
  }
}
