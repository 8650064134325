@import '../../../src/assets/sass/variable.scss';
.mainContent {
  width: 100%;
  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    svg {
      margin-bottom: -1px;
    }
  }
}
