.facilityOtherInformation {
  font-size: 14;
  color: '#312B2B';
  margin-top: 24px !important;
  > div {
    div {
      width: 100% !important;
    }
  }
  .description {
    font-size: 18px;
    color: #7d7d7d;
  }

  .representativeName {
    font-size: 20px;
    font-weight: bold;
  }
}
