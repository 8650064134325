@import '../../../../assets/sass/variable.scss';

.FacilitiesDetailMain {
  margin-top: 50px;
}
.CommentCardMain {
  max-height: 560px;
  overflow-y: scroll;
}
.CommentCardBody {
  margin-top: 24px !important;
  > div {
    padding: 24px !important;
  }
}
.spinnerContent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
