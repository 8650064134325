@import '../../assets/sass/variable.scss';

.serviceContainer {
  .justify {
    justify-content: center;
  }
  @media (max-width: 544px) {
    margin-top: 0;
  }
  .list {
    width: 100%;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.facilitiesList {
  cursor: pointer;
}

.facilityPagination {
  cursor: pointer;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
}

.blogPagination {
  text-align: center;
  ul {
    li {
      background: transparent;
      border-radius: 50%;
      border: none;
      button {
        background-color: transparent !important;
        border: none !important;
      }
      &:active {
        background: $primaryColor;
        border-radius: 50%;
        border-color: $primaryColor;
        a {
          color: $white;
        }
      }
    }
  }
}
