@import '../../../../assets/sass/variable.scss';
.container {
  margin: 25px auto;
}

.tableRow {
  > div {
    width: 100%;
  }
  table {
    width: 100%;
  }
}
.inquiryTable {
  > div {
    overflow: hidden;
    background-color: $white !important;
    padding: 15px;
    border: 1px solid $lightGray;
    border-radius: 10px;
    .search {
      padding: 0 15px;
      .searchInner {
        margin: auto !important;
      }
    }
    th {
      background-color: $white !important;
    }
    ul {
      padding: 0 20px;
      margin-left: 15px;
      overflow: hidden;
    }
  }
}

.inquiryTableColumn {
  display: flex;
  cursor: pointer;
  .title {
    margin-left: 15px;
    p {
      margin-bottom: 0em !important;
    }
  }
  .usrImg {
    border-radius: 30px;
    overflow: hidden;
  }
  p {
    margin-bottom: 0em !important;
  }
  .outline {
    font-size: 25px;
    position: absolute;
    margin-left: 40px;
  }
}
