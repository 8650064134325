@import '../../assets/sass/variable.scss';

.activityReport {
  cursor: pointer;
  margin: 20px auto !important;

  .cardStyle {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
      0px 1px 2px rgba(0, 0, 0, 0.12);
  }

  .text {
    font-size: 14px;
    color: #6c7d8c;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    position: relative;
  }

  .moreIcon {
    span {
      font-size: 26px;
      font-weight: bold;
      transform: rotate(90deg);
      color: #6c7d8c !important;
    }
  }

  .image {
    margin-bottom: 20px;
    > img {
      width: -webkit-fill-available;
    }
  }
}
