@import '../../assets/sass/variable.scss';

.tagMainContent {
  margin-bottom: 5px;
  > span {
    display: flex;
    align-items: center;
    width: fit-content;
    > span {
      padding-left: 3px;
    }
  }
}
