@import '../../assets/sass/variable.scss';

.energy {
  padding: 40px 120px;
  @media (max-width: 544px) {
    padding: 40px 20px;
  }
  .energyTitle {
    color: $darkText;
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .possession {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 300px;
      height: 1px;
      background: $lightGray;
      bottom: -10px;
      left: 0;
    }
    .mg0 {
      margin: 0px;
      color: $darkText;
      font-weight: 500;
    }
    .possessionValue {
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .possessionValueSymbol {
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        background: $secondaryColor;
        display: flex;
        border: 1px solid $secondaryColor;
        border-radius: 8px;
      }
      .possessionValueLabel {
        font-size: 20px;
        font-family: 'Times New Roman';
        color: $primaryColor;
      }
    }
  }

  .energyBoxes {
    width: 100%;
    margin-top: 15px;
  }

  .activeBox {
    border: 1px solid $primaryColor !important;
  }

  .buyBtn {
    margin-top: 20px;
    input {
      width: 260px;
    }
    @media (max-width: 544px) {
      display: flex;
      justify-content: center;
    }

    button {
      height: auto;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      padding: 15px 100px;
      border-radius: 100px;
      text-transform: uppercase;
    }
  }
  .question {
    margin: 40px 0;
    .questionTitle {
      font-size: 25px;
      font-weight: 500;
      color: $gray;
      margin-bottom: 10px;
    }
    .questionList {
      p {
        margin: 0 !important;
        font-weight: 500 !important;
        color: $darkText;
      }
    }
  }
}
