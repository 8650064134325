@import '../../../src/assets/sass/variable.scss';
.progressBar {
  width: 100% !important;
  > div {
    position: relative;
    > div {
      background: $secondaryColor !important;
      > div {
        height: 20px !important;
        background-color: $primaryColor !important;
      }
    }
  }
}
.progressBarPer {
  position: absolute;
  color: $white;
  left: 10%;
  margin-top: 2px;
}
