@import '../../../src/assets/sass/variable.scss';

.planMainContent {
  padding: 20px;
  border: 1px solid $lightGray;
  border-radius: 8px;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
  }
  .titleContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .iconContent {
      display: flex;
      .gold {
        color: $golden;
      }
      .platinum {
        color: #8d8e99;
      }
      .black {
        color: $black;
      }
    }
    p {
      font-family: Times New Roman;
      font-size: 24px;
      font-weight: 400;
      margin-left: 10px;
    }
    .checkIcon {
      svg {
        width: 24px;
        height: 24px;
        color: $gray;
      }
    }
    .activeIcon {
      svg {
        color: $black;
      }
    }
  }
  .planDescription {
    margin-top: 16px;
    .planHeading {
      color: $gray;
      font-size: 12px;
      font-weight: 400;
    }
    .planTitle {
      margin-bottom: 0;
    }
    .planPrice {
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 0;

      p {
        font-size: 14px;
        font-weight: 400;
        color: $gray;
        margin-left: 5px;
      }
    }
    .planSubDes {
      margin-top: 24px;
      width: fit-content;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.activePlan {
  border: 2px solid $primaryColor;
}
.storyMain {
  width: 220px;
}
