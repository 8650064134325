.form {
  width: 100% !important;

  .or {
    margin-right: 5px;
    display: inline-block;
  }
  .resendLink {
    text-align: right;
    margin-top: -24px !important;
  }
}
