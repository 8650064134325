@import '../../assets/sass/variable.scss';

.assitBtn {
  height: 56px;
  border: #a6966d;
  margin-top: 20px;
  border-radius: 44px;
  background-color: #a6966d !important;
  span {
    color: $white;
    text-transform: uppercase;
  }
  &:hover {
    background-color: #a6966d !important;
  }
}
